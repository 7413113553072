/*
Theme Name: ほそ井
Author: warrior_ito
Description: .
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
*/

@charset "utf-8";
@import "moto_style";
$theme_color: #FF3300;
$link_color: blue;
$text_color: #000;
$base_color: #FFFDF4;
//$theme_fonts: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$theme_fonts: YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
//$headline_fonts: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$headline_fonts: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$content_width:1100px;

@mixin cf{
  &:after{
    content:" ";
    display:block;
    clear:both;
  }
}

body{
  font-family: $theme_fonts;
  color: $text_color;
  line-height:1.7;
  font-size:16px;
  background-color: #FFFDF4;
}

#main{
  position: relative;
  overflow: hidden;
  .main_bg{
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    img{
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      position: static;
    }
  }
  .pagewidth{
    img{
      position: relative;
      z-index: 9;
    }
  }
  @media screen and (max-width: 768px) {
    &.photo{
      .pagewidth{
        padding: 0;
      }
    }
  }
}

.post{
  img{
    display: block;
  }
  &-date{
    text-decoration: none;
  }
  a{
    text-decoration: none;
  }
}

.row_inner{
  #contents{
    float: right;
    max-width: 75%;
    @media screen and (max-width: 768px) {
      float: none;
      max-width: 100%;
    }
  }
  #sidebar{
    float: left;
    max-width: 22%;
    width: 100%;
    @media screen and (max-width: 768px) {
      float: none;
      max-width: 100%;
    }
  }
}

.widget{
  margin-bottom: 40px;
  padding: 1em;
  &title{
    margin: -1em -1em 0.7em -1em;
    color: white;
    font-size: 18px;
    background: #39201b;
    padding: 12px 15px;
  }
}

.ct{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .data{
    float: right;
    font-size: 12px;
    width: 100%;
    text-align: right;
  }
  .category{
    float: left;
    width: 100%;
    text-align: left;
  }
}

ul#main-nav li.nav_home,.sp_btn{
  display: none;
}

.screen-reader-text{
  display: none;
}

@media screen and (max-width: 768px) {
  .flexslider .slides li img{
    display: block;
  }
  #headerwrap{
    padding-top: 0;
  }
  #site-logo{
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 1em;
    background: rgba(255,255,255,0.7);
    width: 50px;
    z-index: 999;
  }
  ul#main-nav li.nav_home{
    display: block;
  }
  #footerwrap{
    //padding-bottom: 1em;
    .f_nav{
      margin-bottom: 1em;
      ul{
        li{
          display: block;
          width: 100%;
          border: none;
          a{
            padding: 0.4em 0;
          }
        }
      }
    }
  }
  #global-nav{
    a{
      box-sizing: border-box;
    }
  }
  .hosoi_row01{
    padding-top: 1em;
  }
  .home-post,.blog-post{
    .post{
      max-width: 100%;
      figure{
        text-align: center;
        img{
          margin-left: auto;
          margin-right: auto;
        }
      }
      img{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  #footerwrap .cm_totop a{
    display: none;
  }
  .sp_btn{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background: #d2b688;
    a{
      display: block;
      width: 100%;
      text-align: center;
      background: #d2b688;
      padding: 0.7em 0;
      text-decoration: none;
      &:nth-child(2){
        background: darken(#d2b688,5);
      }
    }
  }
  .navwrap{
    position: fixed;
    transition:right 0.5s;
    padding-top: 25px;
  }
  .responsive-nav-container{
    &.on{
      .navwrap{
        right: 0;
      }
    }
  }
}
